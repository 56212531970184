<template>
  <div class="servicehouse">
    <el-collapse accordion>
      <el-collapse-item title="Alarm Company">
        <Common :data="formDataAlarm" :noteData="noteDataAlarm"  type="alarm company"  ref="child" @clear="clear"  @save="submitServicesProvidersInfor"  @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataAlarm.company_name" />
            </el-form-item>
            <el-form-item label="Alarm Code">
              <el-input v-model="formDataAlarm.code" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Caretaker">
        <Common :data="formDataCaretaker" :noteData="noteDataCaretaker" type="caretaker" ref="child" @clear="clear" @save="submitServicesProvidersInfor" @deleteNote="deleteNote"  >
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Cleaner">
        <Common :data="formDataCleaner" :noteData="noteDataCleaner" type="cleaner"  ref="child"  @clear="clear" @save="submitServicesProvidersInfor" @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataCleaner.company_name" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Electrician">
        <Common :data="formDataElectrician" :noteData="noteDataElectrician" type="electrician" ref="child" @clear="clear" @save="submitServicesProvidersInfor"  @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataElectrician.company_name" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Landscaper">
        <Common :data="formDataLandscaper" :noteData="noteDataLandscaper" type="landscaper"  ref="child" @clear="clear"  @save="submitServicesProvidersInfor" @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataLandscaper.company_name" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Plumber">
        <Common :data="formDataPlumber" :noteData="noteDataPlumber" type="plumber" ref="child" @clear="clear"  @save="submitServicesProvidersInfor"  @deleteNote="deleteNote"  >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataPlumber.company_name" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Trash Service">
        <Common :data="formDataTrash" :noteData="noteDataTrash" type="trash service" ref="child" @clear="clear" @save="submitServicesProvidersInfor"  @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataTrash.company_name" />
            </el-form-item>
            <el-form-item label="Pickup Days">
              <el-select v-model="formDataTrash.pickup_days" multiple  placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label"  :value="item.value" >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
      <el-collapse-item title="Other">
        <Common :data="formDataOther" :noteData="formDataOther" type="other" ref="child" @clear="clear" @save="submitServicesProvidersInfor" @deleteNote="deleteNote" >
          <template slot="form">
            <el-form-item label="Company Name">
              <el-input v-model="formDataOther.company_name" />
            </el-form-item>
          </template>
        </Common>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Common from "./Service/Common.vue";
import { apiCreateServiceProvider,
  apiGetServiceProviders,
  apiGetServiceProvidersComments,
  apiDeleteServiceProviderComment,
  apiUpdateServiceProvider,
} from "../../API/api";
function interceptData(val) {
  if (val.length != 0) {
    return val.slice(0, 6);
  }
}
export default {
  data() {
    return {
      options: [
        {
          value: "Sunday",
          label: "Sunday",
        },
        {
          value: "Monday",
          label: "Monday",
        },
        {
          value: "Tuesday",
          label: "Tuesday",
        },
        {
          value: "Wednesday",
          label: "Wednesday",
        },
        {
          value: "Thursday",
          label: "Thursday",
        },
        {
          value: "Friday",
          label: "Friday",
        },
        {
          value: "Saturday",
          label: "Saturday",
        },
      ],
      formDataAlarm: {},// alarm
      noteDataAlarm: [],
      formDataCaretaker: {},// Caretaker
      noteDataCaretaker: [],
      formDataCleaner: {},// Cleaner
      noteDataCleaner: [],
      formDataElectrician: {},// Electrician
      noteDataElectrician: [],
      formDataLandscaper: {},// Landscaper
      noteDataLandscaper: [],
      formDataPlumber: {},  // Plumber
      noteDataPlumber: [],
      formDataTrash: {},// Trash Service
      noteDataTrash: [],
      formDataOther: {},  // Other
      noteDataOther: [],
    };
  },
  components: {
    Common,
  },
  methods: {
    changeData(res) {
      res.results.forEach((item) => {
        if (item.type === "alarm company") {
          this.formDataAlarm = item;
          this.noteDataAlarm = interceptData(item.comments);
        }
        if (item.type === "caretaker") {
          this.formDataCaretaker = item;
          this.noteDataCaretaker = interceptData(item.comments);
        }
        if (item.type === "cleaner") {
          this.formDataCleaner = item;
          this.noteDataCleaner = interceptData(item.comments);
        }
        if (item.type === "electrician") {
          this.formDataElectrician = item;
          this.noteDataElectrician = interceptData(item.comments);
        }
        if (item.type === "landscaper") {
          this.formDataLandscaper = item;
          this.noteDataLandscaper = interceptData(item.comments);
        }
        if (item.type === "plumber") {
          this.formDataPlumber = item;
          this.noteDataPlumber = interceptData(item.comments);
        }
        if (item.type === "trash service") {
          this.formDataTrash = item;
          this.noteDataTrash = interceptData(item.comments);
        }
        if (item.type === "other") {
          this.formDataOther = item;
          this.noteDataOther = interceptData(item.comments);
        }
      });
    },
    deleteNote(id, commentId, type) {
      apiDeleteServiceProviderComment(id.sp_uuid, commentId)
        .then(() => {
          this.getServiceProvidersComments(id.sp_uuid, type);
          this.$refs.child.closeDelNote();
        }).catch(() => {
          this.$message.error("Please delete again");
        });
    },
    submitServicesProvidersInfor(val, type) {
      let id = val.sp_uuid;
      if (id) {
        delete val.comments;
        apiUpdateServiceProvider(id, val).then((res) => {
            this.$message.success("Modification succeeded");
          }).catch((err) => {
            this.$message.error("Modification failed");
          });
      } else {
        let obj = {
          type: type,
          listing: this.$route.params.id,
        };
        val = Object.assign(val, obj);
        apiCreateServiceProvider(val).then((res) => {
            if (res.type === "alarm company") {
              this.formDataAlarm = res;
            }
            if (res.type === "caretaker") {
              this.formDataCaretaker = res;
            }
            if (res.type === "cleaner") {
              this.formDataCleaner = res;
            }
            if (res.type === "electrician") {
              this.formDataElectrician = res;
            }
            if (res.type === "landscaper") {
              this.formDataLandscaper = res;
            }
            if (res.type === "plumber") {
              this.formDataPlumber = res;
            }
            if (res.type === "trash service") {
              this.formDataTrash = res;
            }
            if (res.type === "other") {
              this.formDataOther = res;
            }
            this.$message.success("Saved successfully");
          }).catch((err) => {
            this.$message.error("Save failed");
          });
      }
    },
    clear(item) {
      if (item === "alarm company") {
        this.formDataAlarm.company_name = this.formDataAlarm.code ="";
      }  else if (item === "cleaner") {
        this.formDataCleaner.company_name ="";
      } else if (item === "electrician") {
        this.formDataElectrician.company_name = "";
      } else if (item === "landscaper") {
        this.formDataLandscaper.company_name = "";
      } else if (item === "plumber") {
        this.formDataPlumber.company_name = "";
      } else if (item === "trash service") {
        this.formDataTrash.company_name = "";
        this.formDataTrash.pickup_days =  [];
      } else {
        this.formDataOther.company_name ="";
      }
    },
    getServiceProvider() {
      apiGetServiceProviders({ listing: this.$route.params.id }).then((res) => {
        this.changeData(res);
      });
    },
    getServiceProvidersComments(id, type) {
      apiGetServiceProvidersComments(id, { limit: 6 }).then((res) => {
        if (type === "alarm company") {
          this.noteDataAlarm = res.results;
        } else if (type === "caretaker") {
          this.noteDataCaretaker = res.results;
        } else if (type === "cleaner") {
          this.noteDataCleaner = res.results;
        } else if (type === "electrician") {
          this.noteDataElectrician = res.results;
        } else if (type === "landscaper") {
          this.noteDataLandscaper = res.results;
        } else if (type === "plumber") {
          this.noteDataPlumber = res.results;
        } else if (type === "trash service") {
          this.noteDataTrash = res.results;
        } else {
          this.noteDataOther = res.results;
        }
      });
    },
  },
  created() {
    this.getServiceProvider();
  },
};
</script>
<style lang="scss" scoped>
.servicehouse {
  padding: 20px;
  /deep/ .el-collapse-item__header,
  /deep/ .el-collapse-item__wrap,
  /deep/ .el-collapse {
    border: none;
  }

  /deep/ .el-collapse-item__header {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #074059;
    text-align: left;
    .el-collapse-item__arrow {
      margin-left: 20px;
    }
  }
  /deep/ .el-input__inner {
    line-height: normal;
    height: 40px;
  }
}
</style>