
<template>
  <div class="template_box">
    <div class="form-alarm">
      <el-form :model="data" ref="data">
        <div class="row">
          <slot name="form"></slot>
          <el-form-item
            label="Contact Name"
            prop="contact_name"
            :rules="rules.require"
          >
            <el-input v-model="data.contact_name" />
          </el-form-item>
          <el-form-item label="Email" prop="email" :rules="rules.email">
            <el-input v-model="data.email" />
          </el-form-item>
          <el-form-item label="Work Phone">
            <el-input
              v-model="data.work_phone"
              maxlength="22"
              @input="(e) => (data.work_phone = changePhone(e))"
            />
          </el-form-item>
          <el-form-item label="Cell Phone">
            <el-input
              v-model="data.cell_phone"
              maxlength="22"
              @input="(e) => (data.cell_phone = changePhone(e))"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <p class="sh_btn">
      <el-button @click="clear" class="light-blue-button">Clear</el-button>
      <el-button @click="save" class="primary-button">Save Changes</el-button>
    </p>
    <p v-if="data && data.sp_uuid" class="note-title">Notes</p>
    <!-- JSON.stringify(data) != '{}'" -->
    <Note
      v-if="data && data.sp_uuid"
      :noteData="table"
      ref="note"
      @createNote="createNote"
    >
      <template slot="table_btn">
        <el-table-column width="100px">
          <template
            slot-scope="scope"
            v-if="
              $store.state.userInfo.is_admin === true ||
              scope.row.user === $store.state.userInfo.user_id
            "
          >
            <el-button
              @click="deleteNote(scope.row.comment_uuid)"
              type="text"
              size="small"
              icon="el-icon-delete"
              style="color: #d46464; font-size: 20px"
            ></el-button>
          </template>
        </el-table-column>
      </template>
      <template slot="note_btn">
        <div class="add_note">
          <a @click="$refs.note.show = true">+ Add Note</a>
        </div>
      </template>
    </Note>
    <Del-Note @del="delNote" :title="title" ref="delInfor"></Del-Note>
  </div>
</template>
<script>
import Note from "../../common/Note";
import { Minixs } from "../../../js/mixins";
import DelNote from "../../common/DelDialog";
import { apiCreateServiceProviderComment } from "../../../API/api";
export default {
  components: { Note, DelNote },
  mixins: [Minixs],
  props: ["type", "data", "noteData"],
  data() {
    return {
      select_service_provider: "",
      note: "",
      title: "Are you sure to delete this note?",
      id: "",
      rules: {
        email: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        require: [
          {
            required: true,
            message: "this information is required",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  computed: {
    table() {
      return this.noteData;
    },
  },
  methods: {
    delNote() {
      this.$emit("deleteNote", this.data, this.id, this.type);
    },
    clear() {
      this.data.contact_name =
        this.data.email =
        this.data.work_phone =
        this.data.cell_phone =
          "";
      this.$emit("clear", this.type);
    },
    save() {
      this.$refs.data.validate((valid) => {
        if (valid) {
          this.$emit("save", this.data, this.type);
        } else {
          return false;
        }
      });
    },
    deleteNote(id) {
      this.$refs.delInfor.show = true;
      this.id = id;
    },
    changeSelect(id) {
      this.$emit("changeSelect", id, this.type);
    },
    createNote(content) {
      apiCreateServiceProviderComment(this.data.sp_uuid, { content })
        .then((res) => {
          this.table.unshift(res);
          this.$refs.note.note = "";
          if (this.table.length > 6) this.table.pop();
          this.$message.success("Success");
        })
        .catch((err) => {
          if (err.response.data.detail === "Not found.")
            return this.$message.error(
              "Notes can only be created by the one who created it."
            );
          this.$message.error(err.response.data.detail);
        })
        .finally(() => {
          this.$refs.note.show = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.template_box {
  margin-bottom: 30px;
  .sh_btn {
    text-align: right;
  }
  .form-alarm {
    .row {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      /deep/ .el-form-item {
        width: calc(calc(100% - 20px) / 2);
        margin-bottom: 10px;
        .el-form-item__label {
          line-height: 24px;
          font-size: 14px;
          &::before {
            display: none;
          }
        }
        .el-select,
        .el-input {
          width: 100%;
        }
      }
    }
  }
  .note-title {
    text-align: left;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 18px;
    color: #36425d;
  }
  .add_note {
    text-align: left;
    margin-right: 10px;
    margin-top: 20px;
    a {
      color: #678993;
      text-decoration: underline;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 14px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 992px) {
    .form-alarm .row {
      display: block;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
</style>